import React, { useState, useMemo, useEffect } from "react";
import tw, { styled } from "twin.macro";
import { graphql, Link } from "gatsby";
import _ from "lodash";
import moment from "moment-timezone";
import Layout from "../components/Layout";
import Header from "@shared/components/Header";
import Icon from "@shared/components/Icon";
import ScheduleList from "@shared/components/ScheduleList";
import Calendar from "@shared/components/Calendar";
import { TZ } from "@shared/constants";
import { Popover } from "react-tiny-popover";

import {
  getStats,
  addNumberSuffix,
  hasPassed,
  getFilteredEventsAndGamesByDate,
  getLeagueOptions,
  getOpponentOptions,
} from "@shared/utils";
import ScheduleFilters from "@shared/components/ScheduleFilters";

import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { formatTeamName, HOME_TEAMS, MAIN_COLOR, SITE } from "../config";
import { useSiteFilter } from "../context/SiteFilterContext";
import TeamSelectionLarge from "../components/TeamSelectionLarge";

const Container = styled.div`
  ${tw`w-full flex flex-col p-5`}
`;
const RosterLink = styled(Link)`
  ${tw`flex items-center text-gray-500`}
`;

const LinkText = styled.span`
  ${tw`underline`}
`;

const TopBar = styled.div`
  ${tw`w-full flex flex-col sm:flex-row items-center sm:justify-between sticky py-4 text-xl left-0`}
`;

const ActionContainer = styled.div`
  ${tw`flex justify-center items-center`}
`;
const ButtonContainer = styled.div`
  ${tw`flex mt-4 sm:my-0 [align-self: flex-end]`}
  ${tw`sm:flex `}
`;
const DateContainer = styled.div`
  ${tw`text-white py-0.5 px-4 border-[1px] border-gray-300 bg-[#EC1F27] text-xl [line-height: 2em] min-w-[160px] text-center`}
`;

const ToggleButton = styled.button`
  ${tw`border border-gray-300 bg-[#EC1F27] h-9 px-3 flex justify-center items-center`}

  &:disabled {
    opacity: 0.3;
  }
  & > .material-icons {
    ${tw`text-2xl text-white`}
  }
`;

const ActionButton = styled.button`
  ${tw`mx-1 sm:mx-2 flex items-center border border-gray-200 sm:px-2 sm:py-1 rounded not-disabled:hover:bg-gray-200 `}
  &:disabled > img {
    opacity: 0.2;
  }
  &:disabled > span {
    opacity: 0.2;
  }
  & > span {
    ${tw`ml-0.5 font-bold text-sm sm:text-base hidden sm:inline`}
  }
`;

const Svg = styled.img`
  ${tw`w-4 h-4`}
`;

const ActionSvg = styled.img`
  ${tw`w-8 h-8`}
`;

const LiveStreamContainer = styled.div`
  ${tw`flex flex-col items-center mt-4`}
`;
const LiveStreamText = styled.div`
  ${tw`text-2xl sm:text-3xl my-1 text-black/70 text-center`}
`;

const LiveButtonContainer = styled.a`
  ${tw`bg-[#EC1F27] text-white flex items-center px-4 py-0.5 text-sm font-bold hover:not-disabled:opacity-80 transition-opacity rounded my-4`}
  & > .material-icons {
    ${tw`mr-2 text-4xl`}
  }
`;
const LinkContainer = styled.div`
  ${tw`flex items-center`}
`;

const BoxScoreLink = styled.a`
  ${tw`bg-[#EC1F27] text-white flex items-center mx-1 px-4 py-0.5 text-sm font-bold hover:not-disabled:opacity-80 transition-opacity rounded my-4`}
  & > .material-icons {
    ${tw`text-4xl`}
  }
`;

const Record = styled.div`
  ${tw`flex justify-center`}
`;

const RecordItem = styled.div`
  ${tw`p-2 text-2xl sm:text-4xl font-bold`}
`;

const FilterButton = styled.button`
  ${tw`border border-gray-300 text-xs font-bold text-black rounded sm:mb-2 sm:ml-auto mr-1 flex items-center justify-center`}

  & > .material-icons {
    ${tw`text-[32px]`}
  }
`;

const TitleContainer = styled.h4`
  ${tw`
    bg-[#EC1F27] text-white font-bold flex justify-center
    p-2.5
  `}
`;

const ShowSelectButton = styled.div`
  ${tw`text-gray-500 cursor-pointer`}
`;

const HeaderLinkContainer = styled.div`
  ${tw`flex justify-between my-3`}
`;

const SchedulePage = ({ data, location }) => {
  const {
    homeTeam: currentHomeTeam,
    filterDataByTeam,
    setHomeTeam,
  } = useSiteFilter();
  let filteredData = filterDataByTeam(data, currentHomeTeam);

  const {
    markdownRemark: {
      frontmatter: { seo, title, homeGameColor, awayGameColor, promoGameColor },
    },
    allGames,
    allTeams,
    allEvents,
    homepage,
  } = filteredData;
  const allGameNodes = allGames.edges;
  const allEventNodes = allEvents.edges;
  const currentYear = new Date().getFullYear();
  const defaultDate = getDefaultDate(allGameNodes, currentYear, TZ);

  const teamsByName = _.keyBy(
    allTeams.edges,
    (edge) =>
      _.get(edge, "node.frontmatter.id", "") ||
      _.get(edge, "node.frontmatter.name", "")
  );

  const breakpoints = useBreakpoint();
  const isMobile =
    Object.values(breakpoints).reduce((a, b) => (b ? a + 1 : a), 0) === 0;
  const [view, setView] = useState(isMobile ? "list" : "calendar");
  // in ISO format
  const [currentDate, setCurrentDate] = useState(defaultDate);

  // game filters
  const [mobileFilterOpen, setMobileFilterOpen] = useState(false);
  const [filters, setFilters] = useState({
    weekday: null,
    homeAway: null,
    opponent: null,
    // promo: null,
    location: null,
    league: null,
  });

  useEffect(() => {
    if (location.search) {
      const searchParams = new URLSearchParams(location.search);
      const team = searchParams.get("team");
      // const storedTeam = localStorage.getItem("homeTeam");
      const formattedTeamName = (team || "").replace("-", " ");
      if (formattedTeamName && HOME_TEAMS.includes(formattedTeamName)) {
        setHomeTeam(formattedTeamName, location);
      }
      //  else if (storedTeam) {
      //   setHomeTeam(storedTeam, location);
      // }
    }
  }, [location.search]);

  const { dateFilteredGames, dateFilteredEvents } =
    getFilteredEventsAndGamesByDate(
      allGameNodes,
      allEventNodes,
      currentDate,
      TZ
    );
  const locationOptions = getLocationOptions(dateFilteredGames, teamsByName);
  const leagueOptions = getLeagueOptions(dateFilteredGames);

  const resetFilters = () => {
    setFilters({
      weekday: null,
      homeAway: null,
      opponent: null,
      // promo: null,
      location: null,
      league: null,
    });
  };

  const handleChangeMonth = (increment) => {
    const newDate = moment
      .tz(currentDate, TZ)
      .add(increment, "month")
      .toISOString();
    setCurrentDate(newDate);
  };

  const record = useMemo(() => {
    const formattedGames = allGameNodes.map((node) => {
      const { date, yr, homeTeam, awayTeam, field, stats, numberSuffix } =
        node.node.frontmatter;
      return {
        yr,
        date,
        homeTeam,
        awayTeam,
        field,
        homeScore: stats.finalScore.home,
        awayScore: stats.finalScore.away,
        numberSuffix,
      };
    });
    return getTeamRecordFromGames(currentHomeTeam, formattedGames);
  }, [allGameNodes, currentHomeTeam]);
  const momentCurrDate = moment.tz(currentDate, TZ);
  const homeTeams = currentHomeTeam ? [currentHomeTeam] : HOME_TEAMS;
  addNumberSuffix(allGameNodes, true);

  const opponentOptions = getOpponentOptions(dateFilteredGames, homeTeams);

  let { filteredGames, filteredEvents } = getFilteredEventsAndGames(
    dateFilteredGames,
    dateFilteredEvents,
    filters,
    TZ,
    homeTeams
  );
  // combine and sort events/games
  const eventAndGames = _.sortBy(
    [...filteredGames, ...filteredEvents],
    (item) => {
      const { mdType, startDate, date } = item.node.frontmatter;
      if (mdType === "event") {
        return startDate;
      } else {
        return date;
      }
    }
  );
  const hasFilter =
    Object.values(filters).filter((value) => value !== null).length !== 0;

  const Filters = (
    <ScheduleFilters
      opponentOptions={opponentOptions}
      locationOptions={locationOptions}
      leagueOptions={leagueOptions}
      filters={filters}
      setFilters={setFilters}
      resetFilters={resetFilters}
      hasFilter={hasFilter}
      color={MAIN_COLOR}
      site={SITE}
    />
  );

  const allSelected = homeTeams.length > 1;

  return (
    <Layout
      seoTitle={seo?.title || title}
      seoDescription={seo?.description}
      location={location}
    >
      <Container>
        {!allSelected ? (
          <>
            <Header
              title={`${
                currentHomeTeam ? ` ${formatTeamName(currentHomeTeam)}` : ""
              } Schedule`}
              color={MAIN_COLOR}
              site={SITE}
            />
            <HeaderLinkContainer>
              <RosterLink
                to={`/roster/${currentHomeTeam.replace(" ", "-")}`}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Icon name="keyboard_backspace" />
                <LinkText>{formatTeamName(currentHomeTeam)} Roster</LinkText>
              </RosterLink>
              {/* <ShowSelectButton
                onClick={() => setHomeTeam("", location)}
                style={{ display: "flex", alignItems: "center" }}
              >
                <LinkText>Change Team</LinkText>
                <Icon name="arrow_upward" />
              </ShowSelectButton> */}
            </HeaderLinkContainer>
            {record && (
              <Record>
                <RecordItem>Win: {record.win}</RecordItem>
                <RecordItem>Loss: {record.lose}</RecordItem>
              </Record>
            )}
          </>
        ) : (
          <TitleContainer>
            {/* <TeamSelectionLarge location={location} /> */}
            All Team Schedule
          </TitleContainer>
        )}
        <TopBar>
          <ActionContainer>
            <ToggleButton
              onClick={() => handleChangeMonth(-1)}
              // disabled={momentCurrDate.month() === 0}
            >
              <Icon name="arrow_left" />
            </ToggleButton>
            <DateContainer>{momentCurrDate.format("MMMM YYYY")}</DateContainer>
            <ToggleButton
              onClick={() => handleChangeMonth(1)}
              // disabled={momentCurrDate.month() === 11}
            >
              <Icon name="arrow_right" />
            </ToggleButton>
          </ActionContainer>
          {!isMobile && (
            <ButtonContainer>
              <ActionButton
                onClick={() => setView("list")}
                disabled={view === "list"}
              >
                <ActionSvg src="/img/svg/list.svg" alt="list_view" />
                <span>List</span>
              </ActionButton>
              <ActionButton
                onClick={() => setView("calendar")}
                disabled={view === "calendar"}
              >
                <ActionSvg src="/img/svg/calendar.svg" alt="calendar_view" />
                <span>Calendar</span>
              </ActionButton>
            </ButtonContainer>
          )}
        </TopBar>
        {isMobile ? (
          <>
            <ButtonContainer>
              <Popover
                isOpen={mobileFilterOpen}
                content={Filters}
                padding={8}
                boundaryInset={16}
                positions={["bottom"]}
                onClickOutside={() => setMobileFilterOpen(false)}
              >
                <FilterButton
                  onClick={() => setMobileFilterOpen(!mobileFilterOpen)}
                >
                  <Icon name="tune" />
                </FilterButton>
              </Popover>
              <ActionButton
                onClick={() => setView("list")}
                disabled={view === "list"}
              >
                <ActionSvg src="/img/svg/list.svg" alt="list_view" />
                <span>List</span>
              </ActionButton>
              <ActionButton
                onClick={() => setView("calendar")}
                disabled={view === "calendar"}
              >
                <ActionSvg src="/img/svg/calendar.svg" alt="calendar_view" />
                <span>Calendar</span>
              </ActionButton>
            </ButtonContainer>
          </>
        ) : (
          Filters
        )}
        {view === "list" && (
          <ScheduleList
            eventAndGames={eventAndGames}
            teamsByName={teamsByName}
            homeGameColor={homeGameColor}
            awayGameColor={awayGameColor}
            promoGameColor={promoGameColor}
            timezone={TZ}
            site={SITE}
            color={MAIN_COLOR}
            homeTeams={homeTeams}
            currentMonth={momentCurrDate.format("MMMM")}
          />
        )}
        {view === "calendar" && (
          <Calendar
            currentDate={currentDate}
            games={filteredGames}
            events={filteredEvents}
            teamsByName={teamsByName}
            homeGameColor={homeGameColor}
            awayGameColor={awayGameColor}
            promoGameColor={promoGameColor}
            timezone={TZ}
            site={SITE}
            homeTeams={homeTeams}
          />
        )}
      </Container>
    </Layout>
  );
};

export default SchedulePage;

export const pageQuery = graphql`
  query SchedulePageQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "schedule-page" } }) {
      frontmatter {
        seo {
          title
          description
        }
        title
        homeGameColor
        awayGameColor
        promoGameColor
      }
    }
    allGames: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "game-post" } } }
      sort: { order: ASC, fields: frontmatter___date }
    ) {
      edges {
        node {
          frontmatter {
            yr
            date
            homeTeam
            awayTeam
            # showBuyTicket
            # ticketLink
            # liveScoreLink
            # promoGame
            # promoDescriptions
            bgColor
            tv
            radio
            stats {
              finalScore {
                home
                away
              }
              inningStats {
                home
                away
              }
            }
            field
            competition
          }
          id
          html
        }
      }
    }
    allTeams: allMarkdownRemark(
      filter: { frontmatter: { mdType: { eq: "team" } } }
    ) {
      edges {
        node {
          frontmatter {
            name
            id
            logo {
              childImageSharp {
                gatsbyImageData(height: 75)
              }
            }
            watchLiveLink
            teamSeasonLink
            location {
              abbreviation
            }
          }
        }
      }
    }
    allEvents: allMarkdownRemark(
      filter: { frontmatter: { mdType: { eq: "event" } } }
      sort: { order: ASC, fields: frontmatter___startDate }
    ) {
      edges {
        node {
          frontmatter {
            mdType
            name
            startDate
            endDate
            highlightColor
          }
        }
      }
    }
  }
`;

const getDefaultDate = (games, year, timezone) => {
  const currentDate = moment().tz(timezone).toISOString();
  const upcomingGamesForCurrentYear = games.filter((game) => {
    const isCurrentYear =
      moment.tz(game.node.frontmatter.date, timezone).year() === parseInt(year);
    const isFutureGame = game.node.frontmatter.date > currentDate;
    return isCurrentYear && isFutureGame;
  });

  const mostRecentUpcomingGameDate = _.get(
    upcomingGamesForCurrentYear,
    "0.node.frontmatter.date"
  );
  if (mostRecentUpcomingGameDate) {
    const date = moment(mostRecentUpcomingGameDate)
      .tz(timezone)
      .startOf("month")
      .toISOString();
    return date;
  } else {
    return parseInt(year) === moment.tz(timezone).year()
      ? moment.tz(timezone).toISOString()
      : moment
          .tz(timezone)
          .year(parseInt(year))
          .month(0)
          .startOf("month")
          .toISOString();
  }
};

// const getTeamRecord = (allGames, team) => {
//   let record = { total: 0, win: 0, lose: 0 };
//   allGames.forEach((obj) => {
//     const { awayTeam, homeTeam, stats } = obj.node.frontmatter;
//     const gameForTeam = [awayTeam, homeTeam].includes(team);
//     const homeTeamInnings = _.get(stats, "inningStats.home");
//     const awayTeamInnings = _.get(stats, "inningStats.away");
//     if (
//       !gameForTeam ||
//       _.isEmpty(homeTeamInnings) ||
//       _.isEmpty(awayTeamInnings)
//     ) {
//       return;
//     }
//     const { score } = getStats(stats.inningStats);

//     const isHomeGameForTeam = homeTeam === team;
//     const homeGameVictory = score.home > score.away;
//     const awayGameVictory = score.away > score.home;
//     // draw is not counted
//     record.total += 1;
//     if (isHomeGameForTeam) {
//       record.win += homeGameVictory ? 1 : 0;
//       record.lose += awayGameVictory ? 1 : 0;
//     } else {
//       record.win += awayGameVictory ? 1 : 0;
//       record.lose += homeGameVictory ? 1 : 0;
//     }
//   });
//   return record;
// };

const getTeamRecordFromGames = (teamName, games) => {
  let tracker = {
    name: teamName,
    win: 0,
    lose: 0,
    homeWin: 0,
    homeLose: 0,
    homeDraw: 0,
    awayWin: 0,
    awayLose: 0,
    awayDraw: 0,
    streakType: "win",
    streakCount: 0,
  };
  if (!teamName) {
    return;
  }

  for (let i = 0; i < games.length; i++) {
    const { homeTeam, homeScore, awayScore, date } = games[i];
    if (!hasPassed(date)) {
      continue;
    }

    const isHomeGame = _.toLower(homeTeam) === teamName;
    let gameResult;
    if (!_.isNil(homeScore) && !_.isNil(awayScore)) {
      if (homeScore > awayScore) {
        gameResult = isHomeGame ? "win" : "lose";
      } else if (homeScore < awayScore) {
        gameResult = isHomeGame ? "lose" : "win";
      } else {
        gameResult = "draw";
      }
    }
    if (_.isNil(gameResult)) {
      continue;
    } else {
      tracker.win += gameResult === "win" ? 1 : 0;
      tracker.lose += gameResult === "lose" ? 1 : 0;
      tracker.homeWin += gameResult === "win" && isHomeGame ? 1 : 0;
      tracker.homeLose += gameResult === "lose" && isHomeGame ? 1 : 0;
      tracker.homeDraw += gameResult === "draw" && isHomeGame ? 1 : 0;
      tracker.awayWin += gameResult === "win" && !isHomeGame ? 1 : 0;
      tracker.awayLose += gameResult === "lose" && !isHomeGame ? 1 : 0;
      tracker.awayDraw += gameResult === "draw" && !isHomeGame ? 1 : 0;

      if (gameResult === tracker.streakType) {
        tracker.streakCount += 1;
      } else {
        tracker.streakCount = 1;
        tracker.streakType = gameResult;
      }
    }
  }

  return {
    name: tracker.name,
    win: tracker.win,
    lose: tracker.lose,
    homeRecord: `${tracker.homeWin}-${tracker.homeLose}-${tracker.homeDraw}`,
    awayRecord: `${tracker.awayWin}-${tracker.awayLose}-${tracker.awayDraw}`,
    streak: `${_.upperCase(tracker.streakType[0])}${tracker.streakCount}`,
  };
};

const getLocationOptions = (games) => {
  const gamesWithUniqueField = _.uniqBy(
    games,
    (game) => game.node.frontmatter.field
  );

  const locationOptions = gamesWithUniqueField.map((game) => {
    const fieldName = _.get(game, "node.frontmatter.field", "");
    return {
      value: fieldName,
      label: fieldName,
    };
  });
  return locationOptions;
};

const getFilteredEventsAndGames = (
  games = [],
  events = [],
  filters,
  timezone,
  homeTeams = []
) => {
  let filteredGames = games.filter((game) => {
    const { homeTeam, date, awayTeam, competition } = game.node.frontmatter;
    let match = true;
    const gameDate = moment.tz(date, timezone);
    // filter day of the week
    if (!_.isNil(filters.weekday)) {
      const gameWeekday = gameDate.day();
      if (gameWeekday !== filters.weekday.value) {
        match = false;
      }
    }

    // filter home game
    const homeGame = homeTeams.includes(_.toLower(homeTeam));
    if (!_.isNil(filters.homeAway)) {
      if (homeGame !== filters.homeAway.value) {
        match = false;
      }
    }

    // filter opponent
    const opposingTeam = homeGame ? awayTeam : homeTeam;
    if (!_.isNil(filters.opponent)) {
      if (opposingTeam !== filters.opponent.value) {
        match = false;
      }
    }

    // filter promo
    // if (!_.isNil(filters.promo)) {
    //   if (promoGame !== filters.promo.value) {
    //     match = false
    //   }
    // }

    // filter location
    if (!_.isNil(filters.location)) {
      const location = _.get(game, "node.frontmatter.field");
      if (location !== filters.location.value) {
        match = false;
      }
    }

    // filter league
    if (!_.isNil(filters.league)) {
      if (competition !== filters.league.value) {
        match = false;
      }
    }

    return match;
  });
  const hasFilter =
    Object.values(filters).filter((value) => value !== null).length !== 0;

  let filteredEvents = hasFilter ? [] : events;

  const filteredGamesByTeamDate = _.groupBy(filteredGames, ({ node }) => {
    const { homeTeam, awayTeam, date } = _.get(node, "frontmatter", {});
    return `${awayTeam}|${homeTeam}|${moment(date)
      .tz(TZ)
      .format("YYYY-MM-DD")}`;
  });

  return { filteredGames, filteredEvents, filteredGamesByTeamDate };
};
