import React from "react";
import _ from "lodash";
import Select, { components } from "react-select";
import tw, { styled } from "twin.macro";
import { useSiteFilter } from "../context/SiteFilterContext";
import { HOME_TEAMS, homeTeamMap } from "../config";

const InputContainer = styled.div`
  ${tw`max-w-[250px] mr-0 text-sm`}
`;

const homeTeamsOptions = HOME_TEAMS.map((teamName) => ({
  value: teamName,
  label: homeTeamMap[teamName],
  type: "team",
}));

const TeamSelectionLarge = ({ location }) => {
  const { homeTeam, setHomeTeam } = useSiteFilter();
  const groupedOptions = [
    // {
    //   options: allOption,
    // },
    {
      // label: "Team",
      options: homeTeamsOptions,
    },
  ];

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: "48px",
      height: "48px",
      padding: "0",
      border: "none",
      boxShadow: "none",
      "&:hover": {
        border: "none",
      },
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "0 6px",
      color: "white",
    }),
    input: (provided) => ({
      ...provided,
      margin: "0",
      padding: "0",
      fontSize: "28px",
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: "28px",
      lineHeight: "28px",
      color: "white",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: "28px",
      color: "white",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: "2px",
      color: "white",
    }),
    groupHeading: (provided) => ({
      ...provided,
      backgroundColor: "#EC1F27",
      color: "#000000",
      padding: "8px 12px",
      fontWeight: "bold",
      borderTop: "1px solid white",
    }),
    menuList: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      "::-webkit-scrollbar": {
        width: "8px",
      },
      "::-webkit-scrollbar-track": {
        background: "transparent",
      },
      "::-webkit-scrollbar-thumb": {
        backgroundColor: "#000000",
        borderRadius: "10px",
        border: "1px solid #000000",
      },
    }),
    option: (provided) => ({
      ...provided,
      margin: 0, // Remove any default margin on options
      paddingTop: "4px", // Adjust as needed to control spacing
      paddingBottom: "4px",
      color: "white",
      fontWeight: "bold",
      zIndex: 99,
    }),
  };

  // Custom Group Heading component
  const CustomGroupHeading = (props) => {
    // Check if there's a label, render only if it exists
    if (!props.data.label) return null;
    return <components.GroupHeading {...props} />;
  };

  const getValue = (homeTeam) => {
    let value;
    if (homeTeam) {
      value = _.find(homeTeamsOptions, (option) => option.value === homeTeam);
    } else {
      value = "";
    }
    return value;
  };
  const value = getValue(homeTeam);

  return (
    <InputContainer>
      <Select
        options={groupedOptions}
        // value={_.find(selectOptions, (option) => option.value === homeTeam)}
        value={value}
        onChange={(option) => {
          if (option.type === "team") {
            setHomeTeam(option.value, location);
          } else {
            setHomeTeam("", location);
          }
        }}
        placeholder="Select Team"
        styles={customStyles}
        isSearchable={false}
        theme={(theme) => ({
          ...theme,
          height: "30px",
          colors: {
            ...theme.colors,
            neutral0: "#EC1F27",
            primary: "black",
            primary25: "gray",
          },
        })}
        components={{ GroupHeading: CustomGroupHeading }}
      />
    </InputContainer>
  );
};

export default TeamSelectionLarge;
